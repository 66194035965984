body {
    background-image: url(../../public/assets/fonds/brique5.png);
    background-position: center;
    background-repeat: repeat;
    cursor: url(../../public/assets/cornet.svg) 0 0, auto;
}

.bgbody {
    background-color: RGBa(76, 47, 39, 0.95);
    background-image: url(../../public/assets/sprites/bordures.png), url(../../public/assets/sprites/bordures.png);
    background-position: top left -10px, top right -10px;
    background-repeat: repeat-y, repeat-y;
    min-height: calc(100vh - 52px);
    margin-top: 2px;
    padding-left: 35px;
    padding-right: 35px;
    padding-top: 10px;
    padding-bottom: 20px;
}

.summary {
    position: fixed;
    overflow-y: auto;
    max-height: 80%;
    right: 0;
    z-index: 1000;
}

.hoverprimary:hover {
    background-color: rgba(4, 255, 217, 0.322);
}

.minialaby {
    min-width: 120px;
}

.minia:hover {
    border: 5px dashed black;
}

.is-invisible {
    display: none;
}

p.number {
    background-color: rgba(0, 0, 0, 0);
    position: absolute;
    z-index: 50;
    font-family: "Franklin Gothic", "serif";
    font-weight: bold;
    text-align: left;
    margin: 0;
}

div.grid-horizontal {
    position: absolute;
    z-index: 45;
    height: 0.3%;
    width: 95%;
    background-color: blueviolet;
}

div.grid-vertical {
    position: absolute;
    z-index: 45;
    width: 0.3%;
    height: 100%;
    background-color: blueviolet;
}

.centered {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.text-centered {
    text-align: center;
}

p.version {
    position: fixed;
    right: 2%;
    bottom: 2%;
    color: white;
}

.land-card-title {
    width: 192px;
    word-wrap: break-word;
}

.hr {
    background-color: #FFE;
    border-color: #FFE;
}