.loading-page, .progress-bar-zone {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: center;
}

.loading-page-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.progress-bar {
    border: 1px dashed rgba(255, 255, 255, 0.521);
}