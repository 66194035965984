.is-one-tenth {
    max-width: 10%;
    min-width: 10%;
    align-items: center;
}

.is-one-eighth {
    max-width: 12.5%;
    min-width: 12.5%;
    align-items: center;
}