@charset "utf-8";

$title-size: 3rem;

$table-color: rgb(255, 255, 255);
$table-head-background-color: hsla(204, 71%, 53%, 0.2);
$table-head-cell-color: rgb(0, 209, 178);
$table-background-color: rgba(0, 0, 0, 0);
$table-row-hover-background-color: rgba(0, 209, 178, 0.1);

$link: #6CF;

// $card-background-color: transparent;

$main-color: rgb(76, 47, 39);
$main-color-invert: findColorInvert($main-color);

@import "../../node_modules/bulma/sass/utilities/derived-variables.sass";
$custom-colors: (
  "main-color":($main-color, $main-color-invert),
);

$text-strong: #ffffffc5;

$scheme-main: lighten($main-color, 2%);
$scheme-bis: darken($scheme-main, 4%);
$scheme-ter: darken($scheme-main, 7%);

$scheme-invert: #b3d0d8;
$scheme-invert-bis: lighten($scheme-invert, 3%);
$scheme-invert-ter: lighten($scheme-invert, 6%);

$text: #fff;

$background: $scheme-ter;

$borber: $scheme-bis;
$border-light: $scheme-ter;

$link-hover: $link-dark;

$modal-background-background-color: rgba(0, 0, 0, 0.5);


@font-face {
  font-family: "Franklin Gothic Heavy";
  src: url("./fonts/Franklin\ Gothic\ Heavy\ Regular.ttf");
}


@import "../../node_modules/bulma/bulma.sass";
@import "../../node_modules/@creativebulma/bulma-divider/src/sass/index.sass";
@import "styles.css";