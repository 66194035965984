.level-thumbnail:hover {
    outline: 5px dashed black;
    outline-offset: -5px;
    z-index: 1000;
}

.level-thumbnail {
    height: auto;
    width: 100%;
}

.level-zone {
    align-items: center;
    justify-items: center;
}